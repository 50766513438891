import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import Swal from "sweetalert2";

const Sendcoins = ({ showModal, closeModal, userDetails, userToken }) => {
  const recipientName =
    userDetails?.user?.businessName ||
    userDetails?.user?.firstName + " " + userDetails?.user?.lastName;
  const [currency, setCurrency] = useState({});
  const [formData, setFormData] = useState({
    amount: 0,
    mode: "",
    type: "",
    otp: "",
    note: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [show, setshow] = useState(false);

  const loadUserData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {});
  };

  const loadCoin = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/currency`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCurrency(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadCoin();
    loadUserData();
  }, []);

  /*const handleOtp = (e) => {
    e.preventDefault();
    let check = window.confirm(
      "Are you sure you want to proceed with this transaction?"
    );
    if (check) {
      setIsLoading(true);
      let data = JSON.stringify({ email: userData.email });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/send-otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setshow(true);
          setIsLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            confirmButtonColor: "#3ca151",
            text: "Something went wrong!",
            icon: "error"
          });
        });
    }
  };
*/
  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure you want to send  $${formData.amount} to ${
        userDetails?.user?.businessName ||
        userDetails?.user?.firstName + " " + userDetails?.user?.lastName
      } ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3ca151",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let data = JSON.stringify({
          paymentMode: formData.mode,
          amount: formData.amount / currency?.value,
          recipientUserId: userDetails?.user?._id,
          transactionType: formData.type,
          email: userData.email,
          currencyValue: currency?.value,
          note: formData.note,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_API}/sendpoints`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              title: "Success!",
              text: "Transaction successful!",
              confirmButtonColor: "#3ca151",
              icon: "success",
            });
            window.location.reload();
            closeModal();
            setshow(false);
          })
          .catch(async (error) => {
            await Swal.fire({
              title: "Error!",
              confirmButtonColor: "#3ca151",
              text: error.response.data.message,
              icon: "error",
            });

            closeModal();
            setshow(false);
            window.location.reload();
          });
      } else {
        setIsLoading(false); // Set isLoading to false since the transaction is not happening
        // Additional handling if needed
      }
    });
  };

  return (
    showModal && (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={closeModal}>
            x
          </button>
          <h2 style={{ marginTop: "0px" }}>Send DM</h2>
          <form style={{ marginBottom: "20px" }} onSubmit={handleSubmit}>
            <input
              style={{
                border: "none",
                borderBottom: "1px solid grey",
                outline: "none",
              }}
              type="text"
              placeholder="Recipient Name:*"
              value={recipientName}
              disabled
              required
            />
            <label
              style={{ borderBottom: "1px solid grey", borderRadius: "5px" }}
            >
              $
              <input
                id="amount-input"
                style={{
                  border: "none",
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                }}
                type="number"
                placeholder="Enter amount in dollars*"
                value={
                  formData.amount > 0
                    ? formData.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value) &&
                  setFormData({ ...formData, amount: e.target.value })
                }
                required
              />
            </label>
            <label style={{ fontSize: "12px", marginTop: "10px" }}>
              Approximate DM*
            </label>
            <input
              disabled
              value={(formData.amount / currency?.value).toFixed(3)}
              type="number"
            />

            <select
              style={{
                border: "none",
                borderBottom: "1px solid grey",
                outline: "none",
              }}
              required
              value={formData.type}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
            >
              <option value="">Select Transaction Type*</option>
              {(userData?.profileType === "ngo" ||
                userData?.profileType === "admin" ||
                userData?.profileType === "impactOrg") && (
                <>
                  <option value="Grant">Grant</option>
                  <option value="Sale">Sale</option>
                  <option value="Sale on Credit">Sale on Credit</option>
                </>
              )}
              {(userData?.profileType === "individual" ||
                userData?.profileType === "business" ||
                userData?.profileType === "admin") && (
                <option value="Donation">Donation</option>
              )}
              {userData?.profileType !== "individual" && (
                <option value="Barter">Barter</option>
              )}
              <option value="Gift">Gift</option>
            </select>

            {(formData.type === "Sale") && (
                <select
                  required
                  style={{
                    border: "none",
                    borderBottom: "1px solid grey",
                    outline: "none",
                  }}
                  value={formData.mode}
                  onChange={(e) =>
                    setFormData({ ...formData, mode: e.target.value })
                  }
                >
                  <option value="">Select Payment Mode*</option>

                  <option value={"Cash"}>Cash</option>
                  <option value={"Credit Card"}>Credit Card</option>
                  <option value={"ACH"}>ACH</option>
                  <option value={"Wire Transfer"}>Wire Transfer</option>
                  <option value={"Wallet"}>Wallet</option>
                </select>
              )}

            <textarea
              type="text"
              style={{marginBottom:'10px',fontSize:'15px'}}
              placeholder="Enter a note (optional)"
              value={
                formData.note
              }
              onChange={(e) =>
                setFormData({ ...formData, note: e.target.value })
              }
            />

            <button
              disabled={!(formData.amount > 0 || formData.transactionType)}
              type="submit"
            >
              Send
            </button>
          </form>

          {isLoading && (
            <>
              <BarLoader cssOverride={{ margin: "auto" }} color="#3ca151" />
              <p style={{ textAlign: "center" }}>
                Please wait while your request is being processed.
              </p>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default Sendcoins;
