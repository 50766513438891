import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import Footer from "../common/Footer";

const BulkRegistration = () => {
  const [users, setUsers] = useState([
    {
      email: "",
      firstName: "",
      lastName: "",
      profileType: "individual",
      about: "",
      country: "",
      businessName: "",
    },
  ]);
  const [results, setResults] = useState([]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newUsers = [...users];
    newUsers[index][name] = value;
    setUsers(newUsers);
  };

  const addUser = () => {
    setUsers([
      ...users,
      {
        email: "",
        firstName: "",
        lastName: "",
        profileType: "individual",
        about: "",
        country: "",
        businessName: "",
      },
    ]);
  };

  const removeUser = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      const newUsers = sheet.map((row) => ({
        email: row.Email || "",
        firstName: row.FirstName || "",
        lastName: row.LastName || "",
        profileType: row.ProfileType || "individual",
        about: row.About || "",
        country: row.Country || "",
        businessName: row.BusinessName || "",
      }));
      setUsers(newUsers);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_API}/bulkusers`,
        users,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        await Swal.fire({
          title: "Success!",
          confirmButtonColor: "#3ca151",
          text: "Bulk users registered successfully!",
          icon: "success",
        });

        setResults(response.data.results);
      } else {
        await Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: "Something went wrong! Please try again later.",
          icon: "error",
        });
      }
    } catch (err) {
      await Swal.fire({
        title: "Error!",
        confirmButtonColor: "#3ca151",
        text: "Something went wrong! Please try again later.",
        icon: "error",
      });
      console.error("Error during bulk registration:", err);
    }
  };

  return (
    <>
      <div className="bulk-registration">
        <h1>Bulk Registration</h1>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        <form onSubmit={handleSubmit}>
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Profile Type</th>
                <th>About</th>
                <th>Country</th>
                <th>Business Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="firstName"
                      value={user.firstName}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="lastName"
                      value={user.lastName}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </td>
                  <td>
                    <select
                      name="profileType"
                      value={user.profileType}
                      onChange={(e) => handleInputChange(index, e)}
                    >
                      <option value="impactOrg">Impact Organisation</option>
                      <option value="individual">Individual</option>
                      <option value="ngo">NGO</option>
                      <option value="business">Business</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="about"
                      value={user.about}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="country"
                      value={user.country}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="businessName"
                      value={user.businessName}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </td>
                  <td>
                    <button type="button" onClick={() => removeUser(index)}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="actions">
            <button type="button" onClick={addUser}>
              Add User
            </button>
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className="results">
          {results.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.email}</td>
                    <td>{result.status ? "Success" : "Failed"}</td>
                    <td>{result.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BulkRegistration;
