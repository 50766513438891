import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import OrgLogo from "../../assets/Organization.png";
import IndLogo from "../../assets/Individual.png";
import AdmLogo from "../../assets/Admin.png";

const EditUserModal = ({ userId, onClose, showEdit, userToken, isAdmin }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    profileType: "individual",
    partOfNgo: false,
    about: "",
    referredBy: "",
    profilePic: "",
    walletPoint: 0,
    referCode: "",
    nominatedNGO: "",
    website: "",
    linkedIn: "",
  });
  const [img, setImg] = useState();
  const [users, setUsers] = useState([]);
  const [edit, setedit] = useState(false);

  const loadNGOData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/listusers?org=yes`,
    };

    axios
      .request(config)
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadImage = (e) => {
    e.preventDefault();
    
    console.log(img);

    let data = new FormData();
    data.append("profileImage",img);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/img-upload`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setFormData({...formData,profilePic:response.data.fileUrl});
        Swal.fire({
          title: "Success!",
          text: "Image Uploaded Successfully!",
          icon: "success",
          confirmButtonColor: "#3ca151",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    const loadData = () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/user/${userId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ...response?.data?.data,
            walletPoint: response?.data?.wallet?.walletPoint,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
    loadNGOData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify(formData);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user/${userId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          text: "User Edited Successfully!",
          icon: "success",
          confirmButtonColor: "#3ca151",
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: error.response.data.message,
          icon: "error",
        });
      });
    setedit(true);
    onClose();
  };

  return (
    showEdit && (
      <div className="modal-overlay-formal">
        <div className="modal-edit-formal">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="close-button"
              style={{ fontSize: "25px" }}
              onClick={() => {
                setedit(true);
                onClose();
              }}
            >
              x
            </button>
          </div>
          <h2>
            View/Edit Details
            <button
              style={{ marginLeft: "10px", fontSize: "14px" }}
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Save
            </button>
          </h2>
          <form className="form-formal" onSubmit={handleSubmit}>

          <img className="circular-image"
              src={
                formData?.profilePic ||
                (formData?.profileType === "admin"
                  ? AdmLogo
                  : formData?.profileType === "individual"
                  ? IndLogo
                  : OrgLogo)
              }
              alt="profile"
              style={{ height: "60px", margin: "5px" }}
            />

            <label className="label-formal">
              Profile Picture:{" "}
              <button disabled={!img} type="button" onClick={uploadImage}>
                Upload
              </button>

              <button disabled={!formData.profilePic} type="button" onClick={()=>setFormData({...formData,profilePic:""})}>
                Remove Image
              </button>

            </label>
            <input
              disabled={edit}
              className="input-formal"
              type="file"
              onChange={(e) => setImg(e.target.files[0])}
            />
            <label className="label-formal">First Name:</label>
            <input
              disabled={edit}
              className="input-formal"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            <label className="label-formal">Last Name:</label>
            <input
              disabled={edit}
              className="input-formal"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {(formData.profileType === "ngo" ||
              formData.profileType === "impactOrg") && (
              <>
                <label className="label-formal">Organisation Name:</label>
                <input
                  disabled={edit}
                  className="input-formal"
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                />
              </>
            )}
            {formData.profileType === "business" && (
              <>
                <label className="label-formal">Business Name:</label>
                <input
                  disabled={edit}
                  className="input-formal"
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                />
              </>
            )}
            <label className="label-formal">Email:</label>
            <input
              disabled={edit}
              className="input-formal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label className="label-formal">DM</label>
            <input
              className="input-formal"
              type="number"
              name="walletPoint"
              value={formData.walletPoint.toFixed(3)}
              onChange={handleChange}
              disabled
            />
            <label className="label-formal">Profile Type:</label>
            <select
              className="select-formal"
              name="profileType"
              value={formData.profileType}
              onChange={handleChange}
              disabled={!isAdmin}
            >
              <option value="impactOrg">Impact Organisation</option>
              <option value="business">Business</option>
              <option value="individual">Individual</option>
              <option value="ngo">NGO</option>
            </select>
            <label className="label-formal">Policy Accepted:</label>
            <select
              disabled
              className="select-formal"
              name="policyAcc"
              value={formData.policyAcc}
              onChange={handleChange}
            >
              <option value="true">True</option>
            </select>
            <label className="label-formal">About:</label>
            <textarea
              disabled={edit}
              rows={5}
              className="input-formal"
              type="text"
              name="about"
              placeholder="Write something about you or your organisation"
              value={formData.about}
              onChange={handleChange}
            />
            <label htmlFor="website">Website:</label>
            <input
              type="text"
              id="website"
              name="website"
              value={formData.website}
              onChange={handleChange}
            />
            <label htmlFor="website">LinkedIn Url:</label>
            <input
              type="text"
              id="linkedIn"
              name="linkedIn"
              value={formData.linkedIn}
              onChange={handleChange}
            />

            {formData.profileType !== "ngo" && (
              <>
                <label htmlFor="nominatedNGO">Nominated NGO:</label>
                <select
                  disabled={edit}
                  onChange={(e) =>
                    setFormData({ ...formData, nominatedNGO: e.target.value })
                  }
                >
                  <option value="" selected>
                    Select Organisation
                  </option>
                  {users?.map((u) => (
                    <option value={u._id}>{u?.businessName}</option>
                  ))}
                </select>
              </>
            )}
            <label htmlFor="referCode">Referral Code:</label>
            <input
              type="text"
              disabled={true}
              id="referCode"
              name="referCode"
              value={formData.referCode}
              onChange={handleChange}
            />
          </form>
        </div>
      </div>
    )
  );
};

export default EditUserModal;
