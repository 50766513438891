import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import Footer from "../common/Footer";
import { BarLoader } from "react-spinners";




const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff !important",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <p style={{ margin: 0 }}>{label}</p>
        <p style={{ margin: 0 }}>{`Daily Users: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};




const Demographics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");
  const [interval, setInterval] = useState("weekly");


  const loadData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/adminkpi?period=${interval}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setData(
          response.data.map((item) => ({
            date: moment(item.date).format("DD-MM-YYYY"),
            dailyUsers: item.dailyUsers,
            totalCoinsCirculation: item.totalCoinsCirculation?.toFixed(3),
            coinsHoldingByUsers: item.coinsHoldingByUsers.toFixed(3),
            paymentTransactions: item.paymentTransactions,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  useEffect(() => {
    loadData();
  }, [interval]);

  return (
    <>     
    <div style={{ width: "95%", margin: "50px auto", paddingBottom: "20px" }}>
    <h1 id="" style={{ color: "white" }}>Admin KPI Dashboard</h1>
      <div style={{ marginBottom: "20px" }}>
        <select value={interval} onChange={handleIntervalChange}>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
        {loading&& 
        <BarLoader color="#3ca151" />
      }
      </div>
      <div style={{ width: "90%", margin: "35px 20px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
            <XAxis dataKey="date" stroke="#fff" />
            <YAxis stroke="#fff" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="dailyUsers"
              stroke="#8884d8"
              name="Daily Users"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "90%", margin: "35px 20px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
            <XAxis dataKey="date" stroke="#fff" />
            <YAxis stroke="#fff" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="totalCoinsCirculation"
              stroke="#82ca9d"
              name="Total DM in Circulation"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "90%", margin: "35px 20px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
            <XAxis dataKey="date" stroke="#fff" />
            <YAxis stroke="#fff" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="coinsHoldingByUsers"
              stroke="#ffc658"
              name="DM Holding By Users"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "90%", margin: "35px 20px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
            <XAxis dataKey="date" stroke="#fff" />
            <YAxis stroke="#fff" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="paymentTransactions"
              stroke="#ff7300"
              name="No. Payment Transactions"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Demographics;
