import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from "@react-oauth/google";
import Footer from "../common/Footer";
import { BarLoader } from "react-spinners";
import { jwtDecode } from "jwt-decode";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });
  const [otpSent, setotpSent] = useState(false);
  const [show, setshow] = useState(false);
  const [disbtn, setdisbtn] = useState(false);
  const [otpCount, setOtpCount] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/dashboard");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    setotpSent(true);
    let data = JSON.stringify({ email: formData?.email });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/send-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setOtpCount(otpCount + 1);
        if (response.data.success === true) {
          setdisbtn(true);
          setshow(true);
          setTimeout(() => {
            setotpSent(false);
          }, 30000);
        } else {
          navigate(`/register?email=${formData.email}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        navigate(`/register?email=${formData.email}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify(formData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/verify-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("userToken", response.data?.userToken);
          navigate("/dashboard");
          Swal.fire({
            title: "Success!",
            text: "Logged in successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Wrong OTP",
            text: "Please Try Again",
            confirmButtonColor: "#3ca151",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Wrong OTP",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
      });
  };

  const handleGoogleSubmit = (credentialResponse) => {
    let data = JSON.stringify({ token: credentialResponse?.credential });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/googlelogin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("userToken", response.data?.userToken);
          navigate("/dashboard");
          Swal.fire({
            title: "Success!",
            text: "Logged in successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
        }
      })
      .catch(async (error) => {
        navigate(
          `/register/${credentialResponse?.credential}`
        );
      });
  };

  return (
    <>
      <div className="new-login-container">
        <div className="ch-cotainer">
          <div className="login-video-box">
            <iframe
              height="300"
              width={500}
              src="https://www.youtube.com/embed/mWRnhw1hSzo?si=twrU4XCg2HkDKc3L"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <h4 style={{ textAlign: "center", backgroundColor: "transparent" }}>
              Learn How To Use Donor Money
            </h4>
            <p style={{ textAlign: "center", backgroundColor: "transparent" }}>
              This video shows how to use donor money
              <br /> for NGOs and Donors.
            </p>
          </div>
        </div>
        <div className="ch-cotainer form">
          <div className=" login-form-box">
            <form onSubmit={handleOtp}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Please enter a valid email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {disbtn ? (
                <>
                  <button disabled={otpSent} type="submit">
                    Resend OTP
                  </button>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    Please wait for 30 seconds before requesting a new OTP.
                  </p>
                </>
              ) : (
                <button disabled={otpSent} type="submit">
                  Login / Signup
                </button>
              )}

              <br />
              {loading && <BarLoader color="#3ca151" />}
            </form>
            {show && (
              <form style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
                <p>
                  {otpCount > 1 && "New"} One Time Password (OTP) sent to your
                  email.
                  <br />
                  Please check your email including the spam folder.
                  <br />
                  OTP is valid for 30 minutes.
                </p>
                {
                  //<label htmlFor="pin">Enter OTP:</label>
                }
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter OTP"
                  value={formData.otp}
                  onChange={handleChange}
                  required
                />

                <button type="submit">Login</button>
              </form>
            )}
            <p id="separatorLogin">--Existing Users can Login with Google--</p>
            <div className="googleAuthBox">
              <GoogleLogin
                width={"100%"}
                style={{ margin: "auto", backgroundColor: "transparent" }}
                onSuccess={(credentialResponse) => {
                  handleGoogleSubmit(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                useOneTap
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginForm;
