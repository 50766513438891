import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactGA.initialize("G-9XG9EPJLCP");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <GoogleOAuthProvider clientId="244824284494-qaihm4t39csaj3jhqjllu7l3hcm4789k.apps.googleusercontent.com">
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </CookiesProvider>
);
