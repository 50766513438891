import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaAngleDown } from "react-icons/fa6";

const Navbar = ({ isOpen }) => {
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [isNavDrower, setisNavDrower] = useState(false);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setProfile(response?.data?.data?.profileType);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken]);

  const validator = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/verifyJWT/${userToken}`,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (!response.data.status) {
          localStorage.clear();
          await Swal.fire({
            title: "Session Time Out! Please login again.",
            confirmButtonColor: "#3ca151",
            icon: "warning",
          });

          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    !isOpen && validator();
  });

  const handleLogout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure you want to logout ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3ca151",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
        Swal.fire({
          title: "Success",
          confirmButtonColor: "#3ca151",
          text: "Logged out successfully !",
          icon: "success",
        });
      }
    });
  };
  return (
    <div className="hfA z-40">
      {/* <div className="hfAa" onClick={() => navScreen("/")} /> */}
      <a className="hfAa" href="https://www.donor.money/" />
      <div className="hfAb">
        <div className="dropdown">
          <a
            className="hfAbA"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p style={{ backgroundColor: "transparent", marginRight: "5px" }}>
              Why Donor Money
            </p>{" "}
            <FaAngleDown
              style={{ backgroundColor: "transparent" }}
              size={16}
              color="white"
            />
          </a>
          <div className="dropdown-content">
            <a
              href="https://www.donor.money/DM-Fundamentals"
              target="_blank"
              rel="noreferrer"
            >
              Fundamental
            </a>
            <a
              href="https://www.donor.money/for-Organizations"
              target="_blank"
              rel="noreferrer"
            >
              For Organizations
            </a>
            <a
              href="https://www.donor.money/for-Donors"
              target="_blank"
              rel="noreferrer"
            >
              For Donors
            </a>
            <a
              href="https://www.donor.money/about-us"
              target="_blank"
              rel="noreferrer"
            >
              About Us
            </a>
            <a
              href="https://www.donor.money/Schedule-a-meeting#contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </div>
        </div>

        <div className="dropdown">
          <a
            className="hfAbA"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p style={{ backgroundColor: "transparent", marginRight: "5px" }}>
              Community
            </p>{" "}
            <FaAngleDown
              style={{ backgroundColor: "transparent" }}
              size={16}
              color="white"
            />
          </a>
          <div className="dropdown-content">
          <a href="https://www.donor.money/Impact-Exchange" target="_blank" rel="noreferrer">
              Impact Exchange
            </a>
            <a href="/Community" target="_blank" rel="noreferrer">
              Our Community
            </a>
            <a
              href="https://www.donor.money/events"
              target="_blank"
              rel="noreferrer"
            >
              Events
            </a>
            <a
              href="https://www.donor.money/resources"
              target="_blank"
              rel="noreferrer"
            >
              Resources
            </a>
          </div>
        </div>

        <a
          className="hfAbA"
          target="_blank"
          rel="noreferrer"
          href="https://www.donor.money/Grants"
        >
          Grants
        </a>
        {(profile === "ngo" || profile === "impactOrg") && (
          <a className="hfAbA" href="/myindividuals">
            My Users
          </a>
        )}

        {userToken || !isOpen ? (
          <>
            <a className="hfAbA" href="/dashboard">
              Dashboard
            </a>

            <a className="hfAbA" href="/" onClick={handleLogout}>
              Logout
            </a>
          </>
        ) : (
          <a className="hfAbA" href="/">
            Login/Register
          </a>
        )}

        <a
          id="logbtn"
          className="hfAbB"
          href="https://www.donor.money/buy"
        >
          Buy Donor Money
        </a>

        <div className="hfAbC" onClick={() => setisNavDrower(!isNavDrower)}>
          {isNavDrower ? (
            <div className="hfAbCa">
              {(userToken || !isOpen) && (
                <div className="hfAbCaA">
                  <a className="hfAbAaA" href="/dashboard">
                    Dashboard
                  </a>
                </div>
              )}

              <div className="hfAbCaA">
                <a
                  href="https://www.donor.money/DM-Fundamentals"
                  target="_blank"
                  rel="noreferrer"
                  className="hfAbAaA"
                >
                  Fundamentals
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  href="https://www.donor.money/for-Organizations"
                  target="_blank"
                  rel="noreferrer"
                  className="hfAbAaA"
                >
                  For Organizations
                </a>
              </div>
              <div className="hfAbCaA">
                <a
                  href="https://www.donor.money/for-Donors"
                  target="_blank"
                  rel="noreferrer"
                  className="hfAbAaA"
                >
                  For Donors
                </a>
              </div>
              <div className="hfAbCaA">
                <a className="hfAbAaA" href="/Community">
                  Community
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  href="https://www.donor.money/about-us"
                  target="_blank"
                  rel="noreferrer"
                  className="hfAbAaA"
                >
                  About Us
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  className="hfAbAaA"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.donor.money/resources"
                >
                  Resources
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  href="https://www.donor.money/Schedule-a-meeting#contact"
                  target="_blank"
                  rel="noreferrer"
                  className="hfAbAaA"
                >
                  Contact Us
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  className="hfAbAaA"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.donor.money/Family-Day-2024"
                >
                  Events
                </a>
              </div>

              <div className="hfAbCaA">
                <a
                  className="hfAbAaA"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.donor.money/Grants"
                >
                  Grants
                </a>
              </div>

              {(profile === "ngo" || profile === "impactOrg") && (
                <div className="hfAbCaA">
                  <a className="hfAbAaA" href="/myindividuals">
                    My Users
                  </a>
                </div>
              )}

              {(userToken || !isOpen) && (
                <div className="hfAbCaA">
                  <a className="hfAbAaA" href="/" onClick={handleLogout}>
                    Logout
                  </a>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
