import "./App.css";
import { Route, Routes } from "react-router-dom";
import AdminUser from "./screens/admin/AdminUser";
import Navbar from "./screens/common/Navbar";
import Signup from "./screens/auth/Signup";
import Login from "./screens/auth/Login";
import Dashboard from "./screens/dashboard/Dashboard";
import MyUsers from "./screens/ngo/MyUsers";
import DisputePage from "./screens/dispute/Dispute";
import Community from "./screens/openPages/Community";
import PrivacyPolicy from "./screens/openPages/PrivacyPolicy";
import Notfound from "./screens/openPages/Notfound";
import CookieConsent from "react-cookie-consent";
import Feedback from "./screens/feedback/Feedback";
import ProfileView from "./screens/openPages/ProfileVIew";
import Footer from "./screens/common/Footer";
import Currency from "./screens/openPages/Currency";
import Reward from "./screens/openPages/Reward";
import VerifyToken from "./screens/auth/VerifyToken";


function App() {
  return (
    <div className="App">
      <Routes>
       <Route exact path="/register/:token" element={<><Navbar isOpen={true}/><Signup /></>} />
        <Route exact path="/register" element={<><Navbar isOpen={true}/><Signup /></>} />
        <Route exact path="/" element={<><Navbar isOpen={true}/><Login /></>} />
        <Route exact path="/dashboard" element={<><Navbar isOpen={false}  /><Dashboard /></>} />
        <Route exact path="/admin-user/:ngoId" element={<><Navbar isOpen={false}  /><AdminUser /></>} />
        <Route exact path="/myindividuals" element={<><Navbar isOpen={false}  /><MyUsers /></>} />
        <Route exact path="/dispute" element={<><Navbar  isOpen={true} /><DisputePage /></>} />
        <Route exact path="/dispute/:transId" element={<><Navbar isOpen={true}  /><DisputePage /></>} />
        <Route exact path="/Community" element={<><Navbar isOpen={true}/><Community /><Footer/></>} />
        <Route exact path="/Community/:userName" element={<><Navbar isOpen={true}/> <ProfileView /></>} />
        <Route exact path="/privacypolicy" element={<><PrivacyPolicy /></>} />
        <Route exact path="/feedback" element={<><Feedback /></>} />
        <Route exact path="/currency" element={<><Navbar isOpen={true}/><Currency /><Footer/></>} />
        <Route exact path="/verify-token" element={<><VerifyToken /></>} />


        <Route exact path="*" element={<><Notfound /></>} />
      </Routes>
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies"
        enableDeclineButton
        cookieName="myAwesomeCookieName2"
        containerClasses="cookie-consent-container"
        buttonClasses="cookie-consent-button"
        declineButtonText="Decline"
        declineButtonClasses="cookie-decline-button"
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
