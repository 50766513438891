import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Footer from "../common/Footer";

const UnverifiedUsers = () => {
  const userToken = localStorage.getItem("userToken");
  const [ngos, setNgos] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ngos?.slice(indexOfFirstItem, indexOfLastItem);

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/unverified`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setNgos(response.data.unverifiedUsers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div style={{ margin: "100px auto" }}>
        <h1 id="head_dash" style={{ margin: "30px 150px" }}>
          Unverified Users
        </h1>

        <div
          className="shadow-box admin-box"
          style={{ width: "74%", marginTop: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></div>
          <hr style={{ marginBottom: "20px" }} />
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Profile</th>
                  <th>Country</th>
                  <th>CreatedOn</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((ngo, index) => (
                  <tr key={index}>
                    <td>
                        {ngo.profileType === "ngo" ||
                        ngo.profileType === "impactOrg" ||
                        ngo.profileType === "business"
                          ? ngo.businessName
                          : ngo?.firstName + " " + ngo?.lastName}
                       </td>
                    <td>
                      {ngo.email}
                    </td>
                    <td>
                    {ngo.profileType}
                    </td>
                    <td>
                    {ngo.country}
                    </td>
                    <td>
                    {moment(ngo.createdDate).format("DD-MM-YYYY hh:mm A")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(ngos?.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UnverifiedUsers;
