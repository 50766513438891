import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "../common/Footer";

const AdminDisputesPage = () => {
  const [disputes, setDisputes] = useState([]);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    fetchDisputes();
  }, []);

  const fetchDisputes = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/alldisputes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDisputes(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong! Please try again later.",
          confirmButtonColor: "#3ca151",
          icon: "error",
        });
      });
  };

  const handleStatusUpdate = async (id, newStatus) => {
    let data = JSON.stringify({ status: "resolved" });

    Swal.fire({
      title: "Are you sure this dispute is resolved ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3ca151",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_API}/dispute/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response?.data?.status) {
              Swal.fire({
                title: "Success!",
                text: "Status updated successfully!",
                confirmButtonColor: "#3ca151",
                icon: "success",
              });
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div style={{ width: "95%", margin: "50px auto", paddingBottom: "20px" }}>
        <h1 id="head_dash">Admin Support Page</h1>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Ticket Id</th>
                <th style={{ width: "10%" }}>Fullname</th>
                <th style={{ width: "10%" }}>Email</th>
                <th style={{ width: "10%" }}>Phone</th>
                <th style={{ width: "15%" }}>Transaction Id</th>
                <th style={{ width: "35%" }}>Description</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "10%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {disputes?.map((dispute) => (
                <tr key={dispute._id}>
                  <td>{dispute._id}</td>
                  <td>
                    {dispute.firstName} {dispute.lastName}
                  </td>
                  <td>{dispute.email}</td>
                  <td>{dispute.phone}</td>
                  <td>{dispute.transId}</td>
                  <td>
                    <strong>{dispute.title}</strong>
                    <br />
                    {dispute.description}
                  </td>
                  <td>{dispute.status}</td>
                  <td>
                    {dispute.status === "pending" && (
                      <button onClick={() => handleStatusUpdate(dispute._id)}>
                        Mark as Resolved
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AdminDisputesPage;
